import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion, useDragControls, useMotionValue } from 'framer-motion';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import objectDedupe from '../../../../util/object-dedupe';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import type { MediaItemAsset } from '../../../primitives/media-item';
import { colors } from '../../../primitives/colors';
import Button from '../../../primitives/button';
import ColSet from '../../../primitives/grid/col-set';
import FadeTransition from '../../../animations/transitions/fade-transition';
import Badge from '../../../primitives/badge';
import ArticleCard from './article-card';
import { useLanguage } from '../../../language/language-provider';

export type ArticlesIndexProps = Queries.ArticlesIndexPropsFragment;

const ArticlesIndex = ({ title }: ArticlesIndexProps): JSX.Element => {
    const { language } = useLanguage();
    const staticData = useStaticQuery<Queries.AllArticlesQuery>(graphql`
        query AllArticles {
            au: allDatoCmsArticle(locale: "en-AU", sort: { meta: { publishedAt: DESC }}) {
                nodes {
                    id
                    title
                    excerpt
                    permalink
                    hideForLocale
                    position
                    tag {
                        id
                        title
                    }
                    thumbnail {
                        ...DatoCmsInternalAsset
                    }
                    primaryMedia {
                        ...MediaItemProps
                    }
                }
            }
            uk: allDatoCmsArticle(locale: "en-GB", sort: { meta: { publishedAt: DESC }}) {
                nodes {
                    id
                    title
                    excerpt
                    permalink
                    hideForLocale
                    position
                    tag {
                        id
                        title
                    }
                    thumbnail {
                        ...DatoCmsInternalAsset
                    }
                    primaryMedia {
                        ...MediaItemProps
                    }
                }
            }
            us: allDatoCmsArticle(locale: "en-US", sort: { meta: { publishedAt: DESC }}) {
                nodes {
                    id
                    title
                    excerpt
                    permalink
                    hideForLocale
                    position
                    tag {
                        id
                        title
                    }
                    thumbnail {
                        ...DatoCmsInternalAsset
                    }
                    primaryMedia {
                        ...MediaItemProps
                    }
                }
            }
        }
    `);

    const {
        nodes: allArticles,
    } = staticData[language as 'au' | 'uk' | 'us'];

    const tags = [{ title: 'All', id: 'all' }, ...objectDedupe<any>(allArticles.map(x => x.tag), 'id')];

    const [tagValue, setTagValue] = React.useState<string>('all');
    const tagId = React.useId();

    const filteredArticles = (tagValue === 'all' ? allArticles : allArticles.filter(x => x?.tag?.id === tagValue)).sort((a, b) => a.position - b.position);

    const x = useMotionValue(0);
    const controls = useDragControls();
    const [isDragging, setIsDragging] = React.useState(false);

    const startDrag = (event: React.MouseEvent): void => {
        setIsDragging(true);
        controls.start(event);
    };

    const endDrag = (): void => {
        setIsDragging(false);
    };

    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <div css={{
            // overflow: 'hidden',
            marginTop: '82px',
            marginBottom: '60px',
            [breakpoints.mb]: {
                marginTop: '50px',
                marginBottom: '40px',
            },
        }}
        >
            <Container>
                {tags.length > 2 && (
                    <div
                        css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingBottom: '62px',
                            [breakpoints.mb]: {
                                flexDirection: 'column',
                                paddingBottom: '40px',
                                alignItems: 'flex-start',
                            },
                        }}
                        ref={ref}
                    >
                        <Typography
                            fontSize={{
                                dt: 4453,
                                tb: 3234,
                            }}
                            fontWeight="light"
                            override
                            css={{
                                [breakpoints.mb]: {
                                    width: '100%',
                                    textAlign: 'center',
                                    marginBottom: '40px',
                                },
                            }}
                        >
                            {title}
                        </Typography>
                        <div
                            css={{
                                position: 'relative',
                            }}
                        >
                            <div
                                css={{
                                    position: 'relative',
                                    width: '100%',
                                    pointerEvents: 'none',
                                    [breakpoints.mb]: {
                                        pointerEvents: 'all',
                                    },
                                }}
                                onPointerDown={startDrag}
                                onPointerUp={endDrag}
                                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                            >
                                <motion.div
                                    dragControls={controls}
                                    dragConstraints={ref}
                                    drag="x"
                                    style={{
                                        x,
                                    }}
                                    css={{
                                        [breakpoints.mb]: {
                                            width: 'fit-content',
                                        },
                                    }}
                                >
                                    <ColSet
                                        breakpoints={{
                                            dt: { between: 12 },
                                        }}
                                        css={{
                                            width: 'unset',
                                            [breakpoints.mb]: {
                                                width: 'fit-content',
                                                flex: 'none',
                                            },
                                        }}
                                    >
                                        {tags.map((tag) => {
                                            if (!tag) return null;

                                            const { id, title } = tag;

                                            return (
                                                <Button
                                                    css={{
                                                        flex: 'none',
                                                        position: 'relative',
                                                        backgroundColor: colors.primaryOrange,
                                                        borderRadius: borderRadius.full,
                                                        display: 'inline-block',
                                                        color: colors.shadesWhite,
                                                    }}
                                                    onClick={() => {
                                                        setTagValue(id);
                                                    }}
                                                >
                                                    {tagValue === id && (
                                                        <motion.div
                                                            layoutId={tagId}
                                                            css={{
                                                                position: 'absolute',
                                                                inset: '0',
                                                                width: '100%',
                                                                height: '100%',
                                                                zIndex: 3,
                                                                backgroundColor: colors.primaryPurple,
                                                            }}
                                                            style={{
                                                                borderRadius: borderRadius.large,
                                                            }}
                                                            transition={{
                                                                type: 'spring',
                                                                duration: 0.6,
                                                            }}
                                                        />
                                                    )}
                                                    <Typography
                                                        css={{
                                                            position: 'relative',
                                                            zIndex: 4,
                                                            padding: '6px 16px',
                                                        }}
                                                        fontSize={{ dt: 1322 }}
                                                    >
                                                        {title}
                                                    </Typography>
                                                </Button>
                                            );
                                        })}
                                    </ColSet>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                )}
                <FadeTransition shouldChange={tagValue}>
                    <Row css={{
                        rowGap: '24px',
                        [breakpoints.mb]: {
                            rowGap: '16px',
                        },
                    }}
                    >
                        {filteredArticles.map((article) => {
                            if (article.hideForLocale) {
                                return null;
                            }

                            return (
                                <Col breakpoints={{ dt: { span: 4 } }}>
                                    <ArticleCard
                                        id={article.id}
                                        title={article.title}
                                        excerpt={article.excerpt}
                                        permalink={article.permalink}
                                        thumbnail={article.thumbnail}
                                        primaryMedia={article.primaryMedia as MediaItemAsset}
                                        tag={(
                                            <Badge>
                                                {article?.tag?.title}
                                            </Badge>
                                        )}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </FadeTransition>
            </Container>
        </div>
    );
};

export default ArticlesIndex;

export const query = graphql`
    fragment ArticlesIndexProps on DatoCmsArticlesIndex {
        title
    } 
`;
